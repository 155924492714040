<template>
  <div class="brand">
    <div class="brand-main">
      <div class="new-list">
        <div class="brand-title">品牌含山</div>
        <div class="banner">
          <p>走进含山</p>
          <p>含山大米 助力乡村产业振兴</p>
        </div>
        <div class="list" v-blockLoadMore="loadMoreClassData">
          <div
            :class="['new-item', newId === index ? 'active' : '']"
            v-for="(item, index) in brandList"
            :key="index"
            @click="changeNew(index, item.path, item.type)"
          >
            <img
              v-show="item.type == 'pdf' && newId == index"
              class="new-icon"
              src="@/assets/image/brand/tz_change.png"
              alt=""
            />
            <img
              v-show="item.type !== 'pdf' && newId != index"
              class="new-icon"
              src="@/assets/image/brand/zs.png"
              alt=""
            />
            <img
              v-show="item.type == 'pdf' && newId != index"
              class="new-icon"
              src="@/assets/image/brand/tz.png"
              alt=""
            />
            <img
              v-show="item.type !== 'pdf' && newId == index"
              class="new-icon"
              src="@/assets/image/brand/zs_change.png"
              alt=""
            />
            <div class="new-info">
              <div class="new-title one-txt-cut">
                {{ item.name }}
              </div>
              <span class="new-time">{{ item.date }} </span>
            </div>
          </div>
        </div>
      </div>
      <div class="new-body">
        <iframe
          v-show="!isShow"
          :src="pathUrl"
          frameborder="0"
          class="iframe-box"
          style="width: 100%; height: 100%"
        ></iframe>
        <img
          style="max-width: 100%; max-height: 100%"
          v-show="isShow"
          :src="pathUrl"
          alt=""
        />
      </div>
      <div class="video-list">
        <div class="brand-title">宣传视频</div>
        <div class="vr-box">
          <span>https://hs.darsing.net/vr/</span>
        </div>
        <div class="publicity-list">
          <video :autoplay="false" controls class="video-item">
            <source src="@/assets/image/brand/123.mp4" type="video/mp4" />
          </video>
          <video :autoplay="false" controls class="video-item">
            <source src="@/assets/image/brand/327.mp4" type="video/mp4" />
          </video>
          <video :autoplay="false" controls class="video-item">
            <source src="@/assets/image/brand/4.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
    <MyNav ref="MyNav"></MyNav>
  </div>
</template>

<script>
import { getBrandListApi } from "@/request/api";
import { mapState } from "vuex";
export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
  },
  data() {
    return {
      isShow: true,
      newId: "",
      pathUrl: "",
      tableData: [
        {
          number: 1,
          name: "广德市农业技术服务中心",
          dry: "广德黄金芽",
        },
        {
          number: 2,
          name: "南陵县紫云英产业协会",
          dry: "南陵紫云英",
        },
        {
          number: 3,
          name: "无为市螃蟹产销协会",
          dry: "当涂螃蟹",
        },
      ],
      brandList: [],
      count: 0,
      pn: 1,
    };
  },

  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },
  created() {
    this.getBrandListApi();
  },
  methods: {
    loadMoreClassData() {
      if (this.brandList.length && this.brandList.length == this.count) return;
      this.pn++;
      this.getBrandListApi(this.pn);
    },
    async getBrandListApi() {
      const { code, results } = await getBrandListApi({
        manor_id: this.farmID,
      });
      if (code === 0) {
        this.brandList = [...this.brandList, ...results.data];
        this.pn = results.pn;
        this.count = results.count;
        this.pathUrl = `https://hs.darsing.net/resource/upload/${this.brandList[0].path}`;
        this.newId = 0;
      }
    },
    changeNew(index, path, type) {
      this.isShow = type === "png" ? true : false;
      this.newId = index;
      this.pathUrl = `https://hs.darsing.net/resource/upload/${path}`;
    },
  },
};
</script>

<style lang="less" scoped>
.brand {
  width: 100%;
  height: 100vh;
  padding-top: 62px;
  background: #0e2138;
  padding: 62px 0 0;
  .brand-title {
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    position: relative;
    padding-left: 15px;
    margin-bottom: 35px;
    &::before {
      content: "";
      width: 4px;
      height: 22px;
      background: #3e90e5;
      border-radius: 2px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
  }
  .brand-main {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    background-color: #071a2c;
    .new-list {
      width: 371px;
      height: 100%;

      background: #142d4c;
      padding: 48px 0 0 50px;
      .banner {
        width: 286px;
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 27px;
        margin-bottom: 38px;
        background-size: cover;
        background: url("../assets/image/brand/banner.png") center center
          no-repeat;
        p {
          color: #fff;
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 10px;
          &:nth-of-type(2) {
            font-size: 14px;
            margin-bottom: 0;
            font-weight: 400;
          }
        }
      }
      .list {
        width: 100%;
        height: calc(100% - 270px);
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #3e90e5;
        }
        .new-item {
          width: 284px;
          height: 72px;
          background: #1a385d;
          border: 1px solid #214a7a;
          border-radius: 5px;
          padding: 16px 13px;
          margin-bottom: 18px;
          display: flex;
          align-items: center;
          cursor: pointer;
          &.active {
            background-color: #3e90e5;
            border-color: #3e90e5;
          }
          .new-icon {
            width: 40px;
            height: 40px;
            margin-right: 12px;
          }
          .new-info {
            width: calc(100% - 52px);
            display: flex;
            color: #fff;
            flex-direction: column;
            .new-title {
              width: 100%;
              font-size: 14px;
            }
            .new-time {
              font-size: 12px;
            }
          }
        }
      }
    }
    .new-body {
      width: 1100px;
      height: 100%;
      padding: 32px 0;
      background: #0e2138;
      display: flex;
      flex-direction: column;
      .iframe-box {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 0;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 0;
          background-color: #3e90e5;
        }
        img {
          width: 100% !important;
          max-width: 100% !important;
        }
      }
    }
    .video-list {
      width: 426px;
      height: 100%;

      background: #0e2138;
      padding: 22px;
      .vr-box {
        width: 378px;
        height: 128px;
        border-radius: 4px;
        overflow: hidden;
        background: url("../assets/image/brand/vr.png") center center no-repeat;
        background-size: contain;
        margin-bottom: 28px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 0 10px 19px 10px;
        span {
          font-size: 14px;
          font-weight: 400;
          text-decoration: underline;
          color: #fffd42;
          line-height: 18px;
        }
      }
      .publicity-list {
        width: 100%;
        height: calc(100% - 270px);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #3e90e5;
        }

        .video-item {
          width: 378px;
          height: 227px;
          margin-bottom: 34px;
        }
      }
    }
  }
}
</style>
